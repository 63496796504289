export const sizesCardInfo = {
  lg: {
    artistNameStyle: 'one-line-heading-large-2xl',
    productNameStyle: 'text-regular-lg',
    genresStyle: 'text-regular-sm',
  },
  md: {
    artistNameStyle: 'one-line-heading-small-2xl',
    productNameStyle: 'text-medium-xs',
    genresStyle: 'text-medium-xs',
  },
  sm: {
    artistNameStyle: 'one-line-heading-small-xl',
    productNameStyle: 'text-regular-sm',
    genresStyle: 'text-regular-xs',
  },
  xs: {
    artistNameStyle: 'one-line-heading-small-lg',
    productNameStyle: 'text-regular-xs',
    genresStyle: 'text-regular-xs',
  },
};
