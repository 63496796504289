import { Box, Flex, Text } from '@chakra-ui/react';
import Avatar from '@ui-kit/atoms/avatar/avatar';
import { FC } from 'react';
import { sizesCardInfo } from './constants';
import * as CSS from 'csstype';
import { SpaceProps } from '@chakra-ui/styled-system';
import { AvatarGroup } from '../../organisms/avatar-group';

type Props = {
  artists: {
    avatarUrl: string;
    name: string;
  }[];
  productName: string;
  genres: string;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  m?: SpaceProps['m'];
};

export const CardInfo: FC<Props> = ({
  artists,
  productName,
  genres,
  size = 'md',
  m,
}) => {
  return (
    <Flex
      pt='20px'
      justifyContent='space-between'
      alignItems='flex-start'
      m={m}
    >
      <Box maxW='245px'>
        <Text
          as='h3'
          textStyle={sizesCardInfo[size].artistNameStyle}
          color='text&icon.tx-contrast'
          noOfLines={3}
        >
          {artists.map((item) => item.name).join(' x ')}
        </Text>

        <Text
          textStyle={sizesCardInfo[size].productNameStyle}
          color='text&icon.tx-contrast'
          mt='20px'
        >
          {productName}
        </Text>

        <Text
          mt='10px'
          textStyle={sizesCardInfo[size].genresStyle}
          color='text&icon.tx-secondary'
        >
          {genres}
        </Text>
      </Box>
      <AvatarGroup
        artists={artists}
        size='sm'
        direction='column'
      />
    </Flex>
  );
};

export default CardInfo;
