import dayjs from 'dayjs';

export const calculateDaysLeft = (starDate: Date, endDate: Date) => {
  const current = dayjs();
  const start = dayjs(starDate);
  const end = dayjs(endDate);

  if (end.isBefore(current)) {
    return 0;
  }

  if (start.isAfter(current)) {
    return start.diff(current, 'day');
  }

  return end.diff(current, 'day') + 1;
};

export const calculateProgressStartDay = (from: Date, to: Date) => {
  const current = dayjs();
  const fromDate = dayjs(from);
  const toDate = dayjs(to);
  const needDaysToStart = toDate.diff(fromDate, 'day');
  const passedDays = toDate.diff(current, 'day');

  if (needDaysToStart <= 0) {
    return 1;
  }

  return passedDays / needDaysToStart;
};
