import { Box } from '@chakra-ui/react';

type TProps = {
  height: number;
  progress: number;
  rounded?: boolean;
  variant?: ProgressBarVariant;
};

export type ProgressBarVariant = 'orange' | 'yellow' | 'gray';

const colorMap = {
  orange: 'text&icon.tx-warning',
  yellow: 'background.bg-accent',
  gray: 'background.bg-tertiary',
};

export const ProgressBar = ({
  progress,
  height,
  rounded,
  variant = 'yellow',
}: TProps) => {
  return (
    <Box
      overflow='hidden'
      rounded={rounded ? '10px' : undefined}
      w='full'
      bgGradient='repeating-linear(to-r, grey.800, grey.800 1px, transparent 1px, transparent 4px)'
    >
      <Box
        h={`${height}px`}
        w={`${progress * 100}%`}
        bgGradient={`repeating-linear(to-r, ${colorMap[variant]}, ${colorMap[variant]} 1px, transparent 1px, transparent 4px)`}
      />
    </Box>
  );
};
