/**
 * Formats a number as a currency string.
 * @param value value to format (in larger units, e.g. dollars, **not cents**)
 * @param stripZero option for strip zeros if output value is integer ($12.00 -> $12)
 * @param currency currency ISO code (e.g. 'USD', 'EUR', 'JPY')
 * @returns formatted currency string
 */
export const formatCurrency = (
  value: number,
  stripZero: boolean = false,
  currency = 'USD',
): string => {
  const isValueInteger = Number.isInteger(value);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumFractionDigits: stripZero && isValueInteger ? 0 : undefined,
  });
  return formatter.format(value);
};
