import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  genres: string;
  productName: string;
  artistName: string;
};

export const ProjectCardInfo: FC<Props> = ({
  genres,
  productName,
  artistName,
}) => {
  return (
    <Flex
      direction='column'
      gap='10px'
    >
      <Text
        textStyle='one-line-heading-small-lg'
        noOfLines={3}
      >
        {artistName}
      </Text>
      <Text textStyle='text-regular-xs'>{productName}</Text>
      <Text
        textStyle='text-regular-xs'
        color='text&icon.tx-secondary'
      >
        {genres}
      </Text>
    </Flex>
  );
};
