import {
  AbsoluteCenter,
  Box,
  IconButton,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import { FC, MouseEvent } from 'react';
import IconPause from '../../icons/icon-pause';
import IconPlay from '../../icons/icon-play';
import { Snippet } from '@/features/audio-player/types';
import { usePlayerControls, usePlayList } from '@/features/audio-player';

type Props = {
  onPlay: () => void;
  imageUrl: string;
  snippet?: Snippet;
  href: string;
};

export const ProjectCardCover: FC<Props> = ({
  imageUrl,
  snippet,
  onPlay,
  href,
}) => {
  const { setPlayList } = usePlayList();
  const { play, pause, checkIsActiveTrack } = usePlayerControls();

  const handlePlay = () => {
    if (!snippet) {
      return;
    }

    setPlayList([snippet]);
    checkIsActiveTrack(snippet) ? pause() : play();
    if (onPlay) {
      onPlay();
    }
  };
  const isActive = snippet ? checkIsActiveTrack(snippet) : false;

  return (
    <Box pos='relative'>
      {snippet && (
        <AbsoluteCenter zIndex='docked'>
          <IconButton
            onClick={handlePlay}
            aria-label='play'
            size='sm'
            icon={isActive ? <IconPause size='sm' /> : <IconPlay size='sm' />}
          />
        </AbsoluteCenter>
      )}
      <LinkBox>
        <LinkOverlay href={href}>
          <Box
            rounded='5px'
            overflow='hidden'
            position='relative'
            boxSize='110px'
            flexShrink={0}
            bgImage={imageUrl}
            bgSize='cover'
            bgPos='center'
            bgRepeat='no-repeat'
          />
        </LinkOverlay>
      </LinkBox>
    </Box>
  );
};
