export const cardLabelSizes = {
  lg: {
    boxSize: '445px',
  },
  md: {
    boxSize: '335px',
  },
  sm: {
    boxSize: '290px',
  },
  xs: {
    boxSize: '110px',
  },
};
